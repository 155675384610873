/** App Builder Export */
export const FETCH_AB_EXPORT_ELEMENTS = "FETCH_AB_EXPORT_ELEMENTS";
export const FETCH_AB_EXPORT_ELEMENTS_SUCCESS =
  "FETCH_AB_EXPORT_ELEMENTS_SUCCESS";
export const FETCH_AB_EXPORT_ELEMENTS_ERROR = "FETCH_AB_EXPORT_ELEMENTS_ERROR";

/** App Builder list */
export const FETCH_AB_CUSTOM_BUILD_LIST = "FETCH_AB_CUSTOM_BUILD_LIST";
export const FETCH_AB_CUSTOM_BUILD_LIST_SUCCESS =
  "FETCH_AB_CUSTOM_BUILD_LIST_SUCCESS";
export const FETCH_AB_CUSTOM_BUILD_LIST_ERROR =
  "FETCH_AB_CUSTOM_BUILD_LIST_ERROR";

/** App Builder DELETE */
export const AB_DELETE = "AB_DELETE";
export const AB_DELETE_SUCCESS = "AB_DELETE_SUCCESS";
export const AB_DELETE_ERROR = "AB_DELETE_ERROR";

/** App Builder DUPLICATE */
export const AB_DUPLICATE = "AB_DUPLICATE";
export const AB_DUPLICATE_SUCCESS = "AB_DUPLICATE_SUCCESS";
export const AB_DUPLICATE_ERROR = "AB_DUPLICATE_ERROR";

//Set PageNo Of ABCustom Build List
export const SET_PAGE_AB_CUSTOM_BUILD_LIST = "SET_PAGE_AB_CUSTOM_BUILD_LIST";

//Set Size per Page Of ABCustom Build List
export const SET_SIZE_PER_PAGE_AB_CUSTOM_BUILD_LIST =
  "SET_SIZE_PER_PAGE_AB_CUSTOM_BUILD_LIST";

/** App Builder SHARE LINK */
export const AB_SHARE_LINK = "AB_SHARE_LINK";
export const AB_SHARE_LINK_SUCCESS = "AB_SHARE_LINK_SUCCESS";
export const AB_SHARE_LINK_ERROR = "AB_SHARE_LINK_ERROR";

/** App Builder CREATE VOLUME */
export const AB_CREATE_VOLUME = "AB_CREATE_VOLUME";
export const AB_CREATE_VOLUME_SUCCESS = "AB_CREATE_VOLUME_SUCCESS";
export const AB_CREATE_VOLUME_ERROR = "AB_CREATE_VOLUME_ERROR";

/** To update redux when Volume is deleted */
export const SET_AB_VOLUME = "SET_AB_VOLUME";

/** To update redux when Volume is deleted */
export const SET_AB_LIBRARY = "SET_AB_LIBRARY";

/** Get Library Build */
export const GET_LIBRARY_BUILD = "GET_LIBRARY_BUILD";
export const GET_LIBRARY_BUILD_SUCCESS = "GET_LIBRARY_BUILD_SUCCESS";
export const GET_LIBRARY_BUILD_ERROR = "GET_LIBRARY_BUILD_ERROR";

/*Reset ABCustom Build List */
export const RESET_AB_CUSTOM_BUILD_LIST = "RESET_AB_CUSTOM_BUILD_LIST";

/** SeriesDetail list */
export const FETCH_APP_SERIES_LISTING = "FETCH_APP_SERIES_LISTING";
export const FETCH_APP_SERIES_LISTING_SUCCESS =
  "FETCH_APP_SERIES_LISTING_SUCCESS";
export const FETCH_APP_SERIES_LISTING_ERROR = "FETCH_APP_SERIES_LISTING_ERROR";

/** SeriesDetail DUPLICATE */
export const SERIES_DETAIL_DUPLICATE = "SeriesDetail_DUPLICATE";
export const SERIES_DETAIL_DUPLICATE_SUCCESS = "SeriesDetail_DUPLICATE_SUCCESS";
export const SERIES_DETAIL_DUPLICATE_ERROR = "SeriesDetail_DUPLICATE_ERROR";

/** SeriesDetailListing DELETE */
export const SERIES_DETAIL_DELETE = "SeriesDetail_DELETE";
export const SERIES_DETAIL_DELETE_SUCCESS = "SeriesDetail_DELETE_SUCCESS";
export const SERIES_DETAIL_DELETE_ERROR = "SeriesDetail_DELETE_ERROR";

/** SeriesDetail SHARE LINK */
export const SERIES_DETAIL_SHARE_LINK = "SeriesDetail_SHARE_LINK";
export const SERIES_DETAIL_SHARE_LINK_SUCCESS =
  "SeriesDetail_SHARE_LINK_SUCCESS";
export const SERIES_DETAIL_SHARE_LINK_ERROR = "SeriesDetail_SHARE_LINK_ERROR";

/** SeriesElement List  */
export const FETCH_SERIES_ELEMENT_LISTING = "FETCH_SERIES_ELEMENT_LISTING";
export const FETCH_SERIES_ELEMENT_LISTING_SUCCESS =
  "FETCH_SERIES_ELEMENT_LISTING_SUCCESS";
export const FETCH_SERIES_ELEMENT_LISTING_ERROR =
  "FETCH_SERIES_ELEMENT_LISTING_ERROR";

/** Series Add  */
export const ADD_SERIES = "ADD_SERIES";
export const ADD_SERIES_SUCCESS = "ADD_SERIES_SUCCESS";
export const ADD_SERIES_ERROR = "ADD_SERIES_ERROR";

/** Series SAVE  */
export const SAVE_APP_VOLUME_DATA = " SAVE_APP_VOLUME_DATA";
export const SAVE_APP_VOLUME_DATA_SUCCESS = " SAVE_APP_VOLUME_DATA_SUCCESS";
export const SAVE_APP_VOLUME_DATA_ERROR = " SAVE_APP_VOLUME_DATA_ERROR";

/** Drag drop series  */
export const SERIES_DRAG_DROP = "SERIES_DRAG_DROP";
export const SERIES_DRAG_DROP_SUCCESS = "SERIES_DRAG_DROP_SUCCESS";
export const SERIES_DRAG_DROP_ERROR = "SERIES_DRAG_DROP_ERROR";

/** Series Title SAVE  */
export const SAVE_APP_SERIES_TITLE = "SAVE_APP_SERIES_TITLE";
export const SAVE_APP_SERIES_TITLE_SUCCESS = "SAVE_APP_SERIES_TITLE_SUCCESS";
export const SAVE_APP_SERIES_TITLE_ERROR = "SAVE_APP_SERIES_TITLE_ERROR";

/** To update redux when element is deleted */
export const SET_AB_SECTION = "SET_AB_SECTION";

/** To update redux when SERIES is deleted */
export const SET_AB_SERIES = "SET_AB_SERIES";

//On/Off Slider App Elements
export const SET_VISIBLE_APP_UPDATE = "SET_VISIBLE_APP_UPDATE";

/**App Builder Series Import*/
export const FETCH_AB_SERIES_IMPORT = "FETCH_AB_SERIES_IMPORT";
export const FETCH_AB_SERIES_IMPORT_SUCCESS = "FETCH_AB_SERIES_IMPORT_SUCCESS";
export const FETCH_AB_SERIES_IMPORT_ERROR = "FETCH_AB_SERIES_IMPORT_ERROR";

export const AB_VOLUME_LENGTH = "AB_VOLUME_LENGTH";

export const AB_RESET_SERIES_DATA = "AB_RESET_SERIES_DATA";

/**App Builder Details Import Series By Categories Volume*/
export const FETCH_AB_SERIES_BY_CATEGORIES_VOLUME =
  "FETCH_AB_SERIES_BY_CATEGORIES_VOLUME";
export const FETCH_AB_SERIES_BY_CATEGORIES_VOLUME_SUCCESS =
  "FETCH_AB_SERIES_BY_CATEGORIES_VOLUME_SUCCESS";
export const FETCH_AB_SERIES_BY_CATEGORIES_VOLUME_ERROR =
  "FETCH_AB_SERIES_BY_CATEGORIES_VOLUME_ERROR";

/** Sections Image DropZone Element  */
export const IMAGE_SECTIONS = "IMAGE_SECTIONS";
export const IMAGE_SECTIONS_SUCCESS = "IMAGE_SECTIONS_SUCCESS";
export const IMAGE_SECTIONS_ERROR = "IMAGE_SECTIONS_ERROR";

/** Drag drop section  */
export const SECTION_DRAG_DROP = "SECTION_DRAG_DROP";
export const SECTION_DRAG_DROP_SUCCESS = "SECTION_DRAG_DROP_SUCCESS";
export const SECTION_DRAG_DROP_ERROR = "SECTION_DRAG_DROP_ERROR";

/** APP BUILDER ADD SECTION */
export const AB_DETAIL_ADD_SECTION = "AB_DETAIL_ADD_SECTION";
export const AB_DETAIL_ADD_SECTION_SUCCESS = "AAB_DETAIL_ADD_SUCCESS";
export const AB_DETAIL_ADD_SECTION_ERROR = "AB_DETAIL_ADD_SECTION_ERROR";

export const SET_AB_LIBRARY_FOLDER_ID = "SET_AB_LIBRARY_FOLDER_ID";

/** App Builder save Custom Restore on change */
export const AB_SAVE_CUSTOM_RESTORE = "AB_SAVE_CUSTOM_RESTORE";
export const AB_SAVE_CUSTOM_RESTORE_SUCCESS = "AB_SAVE_CUSTOM_RESTORE_SUCCESS";
export const AB_SAVE_CUSTOM_RESTORE_ERROR = "AB_SAVE_CUSTOM_RESTORE_ERROR";

/** Restore Point List  */
export const FETCH_RESTORE_POINT_LISTING = "FETCH_RESTORE_POINT_LISTING";
export const FETCH_RESTORE_POINT_LISTING_SUCCESS =
  "FETCH_RESTORE_POINT_LISTING_SUCCESS";
export const FETCH_RESTORE_POINT_LISTING_ERROR =
  "FETCH_RESTORE_POINT_LISTING_ERROR";

/**App Builder Details Import Categories Volume*/
export const FETCH_AB_CATEGORIES_VOLUME_LIST =
  "FETCH_AB_CATEGORIES_VOLUME_LIST";
export const FETCH_AB_CATEGORIES_VOLUME_LIST_SUCCESS =
  "FETCH_AB_CATEGORIES_VOLUME_LIST_SUCCESS";
export const FETCH_AB_CATEGORIES_VOLUME_LIST_ERROR =
  "FETCH_AB_CATEGORIES_VOLUME_LIST_ERROR";

/** App Builder RESTOREList */
export const FETCH_AB_RESTORE_LIST = "FETCH_AB_RESTORE_LIST";
export const FETCH_AB_RESTORE_LIST_SUCCESS = "FETCH_AB_RESTORE_LIST_SUCCESS";
export const FETCH_AB_RESTORE_LIST_ERROR = "FETCH_AB_RESTORE_LIST_ERROR";

/** APP Builder Teaching */
export const FETCH_AB_TEACHING_ELEMENTS = "FETCH_AB_ELEMENTS";
export const FETCH_AB_TEACHING_ELEMENTS_SUCCESS = "FETCH_AB_ELEMENTS_SUCCESS";
export const FETCH_AB_TEACHING_ELEMENTS_ERROR = "FETCH_AB_ELEMENTS_ERROR";

export const APP_BUILDER_ACTION_UNDO = "APP_BUILDER_ACTION_UNDO";
export const APP_BUILDER_ACTION_REDO = "APP_BUILDER_ACTION_REDO";
export const APP_BUILDER_BUILD_CHANGE = "APP_BUILDER_BUILD_CHANGE";

export const APP_BUILDER_VOLUME_ACTION_UNDO = "APP_BUILDER_VOLUME_ACTION_UNDO";
export const APP_BUILDER_VOLUME_ACTION_REDO = "APP_BUILDER_VOLUME_ACTION_REDO";
export const APP_BUILDER_VOLUME_BUILD_CHANGE =
  "APP_BUILDER_VOLUME_BUILD_CHANGE";

export const APP_BUILD_REMOVE = "APP_BUILD_REMOVE";
export const STATE_ACTION_APP = "STATE_ACTION_APP";
export const CHANGE_APP_ELEMENTS = "CHANGE_APP_ELEMENTS";
export const CHANGE_VOLUME_ELEMENTS = "CHANGE_VOLUME_ELEMENTS";
export const IS_DUPLICATE = "IS_DUPLICATE";
