import {
  SET_LOADING,
  GET_SUBSCRIPTION_PRODUCT_LIST,
  GET_ACTIVE_SUBSCRIPTION_LIST,
  SET_PAGE,
  SET_PAGE_RECORD,
  SET_TOTAL_RECORDS,
} from "./actionTypes";

import produce from "immer";

const initialState = {
  loading: false,
  subscriptionProduct: [],
  activeSubscriptionsList: [],
  page: 1,
  page_record: 10,
  total_records: 0,
};

const subscriptionReducer = produce((state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_LOADING:
      state.loading = payload;
      break;
    case GET_SUBSCRIPTION_PRODUCT_LIST:
      state.subscriptionProduct = payload;
      break;
    case GET_ACTIVE_SUBSCRIPTION_LIST:
      return {
        ...state,
        loading: false,
        activeSubscriptionsList: payload.rows,
      };

    case SET_PAGE:
      state.page = payload;
      break;
    case SET_PAGE_RECORD:
      state.page_record = payload;
      break;
    case SET_TOTAL_RECORDS:
      state.total_records = payload;
      break;

    default:
      return state;
  }
});

export default subscriptionReducer;
