import { combineReducers } from "redux";
import Auth from "./Auth/reducer";
import Layout from "./Layout/reducer";
import ABReducer from "modules/LessonBuilder/store/reducer";
import MBReducer from "modules/MessageBuilder/store/reducer";
import FolderListing from "./FolderListing/reducer";
import LessonBuilderDetails from "../modules/LessonBuilderDetails/store/reducer";
import ABElementReducer from "../modules/AppBuilderDetails/store/reducer";
import subscription from "../components/common/Subscription/Subscription/reducer";
import tutorial from "modules/Tutorials/store/reducer";

const rootReducer = combineReducers({
  Auth,
  Layout,
  ABReducer,
  MBReducer,
  FolderListing,
  LessonBuilderDetails,
  ABElementReducer,
  subscription,
  tutorial,
});

export default rootReducer;
