const {
  FETCH_AB_CUSTOM_BUILD_LIST,
  FETCH_AB_CUSTOM_BUILD_LIST_SUCCESS,
  FETCH_AB_CUSTOM_BUILD_LIST_ERROR,
  FETCH_AB_FOLDER_BUILD_LIST,
  FETCH_AB_FOLDER_BUILD_LIST_SUCCESS,
  FETCH_AB_FOLDER_BUILD_LIST_ERROR,
  GET_LIBRARY_BUILD_SUCCESS,
  GET_LIBRARY_BUILD,
  GET_LIBRARY_BUILD_ERROR,
  GET_LIBRARY_FOLDER_BY_TYPE,
  GET_LIBRARY_FOLDER_BY_TYPE_SUCCESS,
  GET_LIBRARY_FOLDER_BY_TYPE_ERROR,
  FETCH_FOLDER_DATA,
  FETCH_FOLDER_DATA_SUCCESS,
  FETCH_FOLDER_DATA_ERROR,
  FETCH_AB_FOLDER_DATA,
  FETCH_AB_FOLDER_DATA_SUCCESS,
  FETCH_AB_FOLDER_DATA_ERROR,
  FETCH_BUILD_DATA,
  FETCH_BUILD_DATA_SUCCESS,
  FETCH_BUILD_DATA_ERROR,
  RESET_BUILD_LIST,
  MB_DELETE,
  FETCH_SERIES_DATA,
  FETCH_SERIES_DATA_SUCCESS,
  FETCH_SERIES_DATA_ERROR,
  LB_UPLOAD_BUILD_IMAGE,
  LB_UPLOAD_BUILD_IMAGE_SUCCESS,
  LB_UPLOAD_BUILD_IMAGE_ERROR,
  AB_DUPLICATE,
  AB_DUPLICATE_SUCCESS,
  AB_DUPLICATE_ERROR,
  FETCH_MB_TEACHING_ELEMENTS_BY_CODE,
  FETCH_MB_TEACHING_ELEMENTS_BY_CODE_SUCCESS,
  FETCH_MB_TEACHING_ELEMENTS_BY_CODE_ERROR,
  SET_FOLDER_ID,
  SET_MINISTRY_TYPE,
  SET_MB_LISTING,
  SET_AB_LISTING_BY_FOLDER_ID,
  RESET_AB_FOLDER_BUILD_LIST,
  RESET_FOLDER_DATA,
  SET_TAB_VIEW,
  STORE_ACTIVE_TAB_ID,
  STORE_ACTIVE_BREADCRUMB,
  BUILDER_COMPONENT_CHANGE,
  FETCH_FOLDER_LESSON_DATA_SUCCESS,
} = require("./actionTypes");

const ABInitialState = {
  ABCustomBuildList: [],
  BuildCustomList: [],
  LessonFolderCustomList: [],
  LibraryBuildListing: null,
  shareMBLink: "",
  shareABLink: "",
  loading: false,
  search: "",
  sizePerPageAB: 50,
  pageNoAB: 1,
  sortField: "volume_id",
  sortOrder: "DESC",
  totalSizeAB: 0,
  totalSize: 0,
  folderDetailData: null,
  ABfolderDetailData: [],
  LBShareLink: [],
  folderId: 0,
  ministryType: 0,
  tabView: 2,
  activeTabView: null,
  activeBreadCrumb: null,
  builderComponent: false,
};

// const types = {
//   1: "kids",
//   2: "students",
//   3: "groups",
// };

const ABReducer = (state = ABInitialState, action) => {
  const { type, payload } = action;
  switch (type) {
    /** AB list */
    case FETCH_AB_CUSTOM_BUILD_LIST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_AB_CUSTOM_BUILD_LIST_SUCCESS:
      return {
        ...state,
        ABCustomBuildList: [...payload.data],
        loading: false,
        totalSizeAB: payload.count,
      };
    case FETCH_AB_CUSTOM_BUILD_LIST_ERROR:
      return {
        ...state,
        loading: false,
      };
    //deleting AB VOLUME from redux
    case SET_AB_LISTING_BY_FOLDER_ID:
      return {
        ...state,
        ABCustomBuildList: [
          ...state.ABCustomBuildList.filter(
            item => item.volume_id !== payload.volume_id
          ),
        ],
      };
    case RESET_BUILD_LIST:
      return {
        ...state,
        BuildCustomList: [],
        loading: false,
      };

    /** Get builder by folder id */
    case FETCH_AB_FOLDER_BUILD_LIST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_AB_FOLDER_BUILD_LIST_SUCCESS:
      return {
        ...state,
        ABCustomBuildList: [...payload.data],
        StoreAppLibraryFolderId: "",
        loading: false,
        totalSizeAB: payload.count,
      };
    case FETCH_AB_FOLDER_BUILD_LIST_ERROR:
      return {
        ...state,
        loading: false,
      };

    //RESET  FOLDER LIST OF APP
    case RESET_AB_FOLDER_BUILD_LIST:
      return {
        ...state,
        ABfolderDetailData: [],
        ABCustomBuildList: [],
      };
    /** Build list */
    case FETCH_BUILD_DATA:
      return {
        ...state,
        loading: true,
      };
    case FETCH_FOLDER_LESSON_DATA_SUCCESS:
      return {
        ...state,
        LessonFolderCustomList: [...payload.userFolder],
      };
    case FETCH_BUILD_DATA_SUCCESS:
      return {
        ...state,
        BuildCustomList: [...payload.rows],
        loading: false,
        totalSize: payload.count,
        LibraryBuildListing: null,
      };
    case FETCH_BUILD_DATA_ERROR:
      return {
        ...state,
        loading: false,
      };
    case GET_LIBRARY_BUILD:
      return {
        ...state,
        loading: true,
      };
    case GET_LIBRARY_BUILD_SUCCESS:
      return {
        ...state,
        LibraryBuildListing: action.payload,
        loading: false,
      };
    case GET_LIBRARY_BUILD_ERROR:
      return {
        ...state,
        loading: false,
      };
    /** Get library folder using type */
    case GET_LIBRARY_FOLDER_BY_TYPE:
      return {
        ...state,
        loading: false,
      };
    case GET_LIBRARY_FOLDER_BY_TYPE_SUCCESS:
      return {
        ...state,
        LibraryListing: action.payload,
        loading: false,
      };
    case GET_LIBRARY_FOLDER_BY_TYPE_ERROR:
      return {
        ...state,
        loading: true,
      };
    //Get Folder Detail Data
    case FETCH_FOLDER_DATA:
      return {
        ...state,
        loading: true,
      };
    case FETCH_FOLDER_DATA_SUCCESS:
      return {
        ...state,
        folderDetailData: action.payload,
        sideBar: true,
        loading: false,
      };
    case FETCH_FOLDER_DATA_ERROR:
      return {
        ...state,
        loading: false,
      };
    //Get AB Folder Detail Data
    case FETCH_AB_FOLDER_DATA:
      return {
        ...state,
        loading: true,
      };
    case FETCH_AB_FOLDER_DATA_SUCCESS:
      return {
        ...state,
        ABfolderDetailData: action.payload,
        sideBar: true,
        loading: false,
      };
    case FETCH_AB_FOLDER_DATA_ERROR:
      return {
        ...state,
        loading: false,
      };
    //MB delete
    case MB_DELETE:
      return {
        ...state,
        loading: true,
      };
    //deleting MB MESSAGE from redux
    case SET_MB_LISTING:
      return {
        ...state,
        BuildCustomList: [
          ...state.BuildCustomList.filter(
            item => item.message_build_list_id !== payload.build_id
          ),
        ],
      };
    case FETCH_SERIES_DATA:
      return {
        ...state,
        loading: true,
      };
    case FETCH_SERIES_DATA_SUCCESS:
      return {
        ...state,
        LibraryBuildListing: action.payload.rows,
        totalSize: payload.count,
        loading: false,
      };
    case FETCH_SERIES_DATA_ERROR:
      return {
        ...state,
        loading: false,
      };
    /** Message Builder Teaching list By Code **/
    case FETCH_MB_TEACHING_ELEMENTS_BY_CODE:
      return {
        ...state,
        loading: true,
      };

    case FETCH_MB_TEACHING_ELEMENTS_BY_CODE_SUCCESS:
      return {
        ...state,
        loading: false,
        MBTeachingElements: action.payload,
        totalSize: payload.length,
      };

    case FETCH_MB_TEACHING_ELEMENTS_BY_CODE_ERROR:
      return {
        ...state,
        loading: false,
      };
    //LB Upload Build Media
    case LB_UPLOAD_BUILD_IMAGE:
      return {
        ...state,
        loading: true,
      };
    case LB_UPLOAD_BUILD_IMAGE_SUCCESS:
      return {
        ...state,
        BuildCustomList: state.BuildCustomList.map(i =>
          i.message_build_list_id === payload.id
            ? {
                ...i,
                media_url: payload.media_url,
              }
            : i
        ),
        ABCustomBuildList: state.ABCustomBuildList.map(i =>
          i.volume_id === payload.id
            ? {
                ...i,
                media_url: payload.media_url,
              }
            : i
        ),
        LBShareLink: action.payload,
        loading: false,
      };
    case LB_UPLOAD_BUILD_IMAGE_ERROR:
      return {
        ...state,
        loading: true,
      };
    //AB DUPLICATE
    case AB_DUPLICATE:
      return {
        ...state,
        loading: payload,
      };
    case AB_DUPLICATE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case AB_DUPLICATE_ERROR:
      return {
        ...state,
        loading: false,
      };
    case SET_FOLDER_ID:
      return {
        ...state,
        folderId: payload,
      };
    case SET_MINISTRY_TYPE:
      return {
        ...state,
        ministryType: payload,
      };

    //reset folder data
    case RESET_FOLDER_DATA:
      return {
        ...state,
        folderDetailData: {},
        ABCustomBuildList: [],
      };
    case SET_TAB_VIEW:
      return {
        ...state,
        tabView: payload,
      };
    case STORE_ACTIVE_TAB_ID:
      return {
        ...state,
        activeTabView: payload,
      };
    case STORE_ACTIVE_BREADCRUMB:
      return {
        ...state,
        activeBreadCrumb: payload,
      };
    case BUILDER_COMPONENT_CHANGE:
      return {
        ...state,
        builderComponent: payload,
      };
    default:
      return state;
  }
};
export default ABReducer;
