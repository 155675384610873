/** Message Builder list */
export const FETCH_MB_CUSTOM_BUILD_LIST = "FETCH_MB_CUSTOM_BUILD_LIST";
export const FETCH_MB_CUSTOM_BUILD_LIST_SUCCESS =
  "FETCH_MB_CUSTOM_BUILD_LIST_SUCCESS";
export const FETCH_MB_CUSTOM_BUILD_LIST_ERROR =
  "FETCH_MB_CUSTOM_BUILD_LIST_ERROR";

export const RESET_MB_CUSTOM_BUILD_LIST = "RESET_MB_CUSTOM_BUILD_LIST";

/** Get MB USING FOLDER ID*/
export const GET_MB_FOLDER_BUILD_LIST = "GET_MB_FOLDER_BUILD_LIST";
export const GET_MB_FOLDER_BUILD_LIST_SUCCESS =
  "GET_MB_FOLDER_BUILD_LIST_SUCCESS";
export const GET_MB_FOLDER_BUILD_LIST_ERROR = "GET_MB_FOLDER_BUILD_LIST_ERROR";
/* store pageNo for Mbfolder */
export const SET_PAGE_MB_FOLDER_BUILD_LIST = "SET_PAGE_MB_FOLDER_BUILD_LIST";

/* Reset Mbfolder build list */
export const RESET_MB_FOLDER_BUILD_LIST = "RESET_MB_FOLDER_BUILD_LIST";

/** Lesson Builder SHARE LINK */
export const AB_SHARE_LINK = "AB_SHARE_LINK";
export const AB_SHARE_LINK_SUCCESS = "AB_SHARE_LINK_SUCCESS";
export const AB_SHARE_LINK_ERROR = "AB_SHARE_LINK_ERROR";

// Auto Save Builder Element Api
export const BUILDER_AUTO_SAVE_LOADING = "BUILDER_AUTO_SAVE_LOADING";
