import clonedeep from "lodash.clonedeep";

import {
  FETCH_AB_EXPORT_ELEMENTS,
  FETCH_AB_EXPORT_ELEMENTS_SUCCESS,
  FETCH_AB_EXPORT_ELEMENTS_ERROR,
  FETCH_AB_CUSTOM_BUILD_LIST,
  FETCH_AB_CUSTOM_BUILD_LIST_SUCCESS,
  FETCH_AB_CUSTOM_BUILD_LIST_ERROR,
  SET_PAGE_AB_CUSTOM_BUILD_LIST,
  AB_DUPLICATE,
  AB_DUPLICATE_SUCCESS,
  AB_DUPLICATE_ERROR,
  AB_SHARE_LINK,
  AB_SHARE_LINK_SUCCESS,
  AB_SHARE_LINK_ERROR,
  AB_DELETE,
  AB_DELETE_SUCCESS,
  AB_DELETE_ERROR,
  AB_CREATE_VOLUME,
  AB_CREATE_VOLUME_SUCCESS,
  AB_CREATE_VOLUME_ERROR,
  SET_AB_VOLUME,
  GET_LIBRARY_BUILD,
  GET_LIBRARY_BUILD_SUCCESS,
  GET_LIBRARY_BUILD_ERROR,
  SET_AB_LIBRARY,
  RESET_AB_CUSTOM_BUILD_LIST,
  FETCH_AB_SERIES_IMPORT,
  FETCH_AB_SERIES_IMPORT_SUCCESS,
  FETCH_AB_SERIES_IMPORT_ERROR,
  FETCH_APP_SERIES_LISTING,
  FETCH_APP_SERIES_LISTING_SUCCESS,
  FETCH_APP_SERIES_LISTING_ERROR,
  SERIES_DETAIL_DUPLICATE,
  SERIES_DETAIL_DUPLICATE_SUCCESS,
  SERIES_DETAIL_DUPLICATE_ERROR,
  SERIES_DETAIL_SHARE_LINK,
  SERIES_DETAIL_SHARE_LINK_SUCCESS,
  SERIES_DETAIL_SHARE_LINK_ERROR,
  SERIES_DETAIL_DELETE,
  SERIES_DETAIL_DELETE_SUCCESS,
  SERIES_DETAIL_DELETE_ERROR,
  FETCH_SERIES_ELEMENT_LISTING,
  FETCH_SERIES_ELEMENT_LISTING_SUCCESS,
  FETCH_SERIES_ELEMENT_LISTING_ERROR,
  ADD_SERIES,
  ADD_SERIES_SUCCESS,
  ADD_SERIES_ERROR,
  SAVE_APP_VOLUME_DATA,
  SAVE_APP_VOLUME_DATA_SUCCESS,
  SAVE_APP_VOLUME_DATA_ERROR,
  SERIES_DRAG_DROP,
  SERIES_DRAG_DROP_SUCCESS,
  SERIES_DRAG_DROP_ERROR,
  SAVE_APP_SERIES_TITLE,
  SAVE_APP_SERIES_TITLE_SUCCESS,
  SAVE_APP_SERIES_TITLE_ERROR,
  SET_AB_SECTION,
  SET_AB_SERIES,
  SET_VISIBLE_APP_UPDATE,
  AB_VOLUME_LENGTH,
  AB_RESET_SERIES_DATA,
  FETCH_AB_SERIES_BY_CATEGORIES_VOLUME,
  FETCH_AB_SERIES_BY_CATEGORIES_VOLUME_SUCCESS,
  FETCH_AB_SERIES_BY_CATEGORIES_VOLUME_ERROR,
  IMAGE_SECTIONS,
  IMAGE_SECTIONS_SUCCESS,
  IMAGE_SECTIONS_ERROR,
  SECTION_DRAG_DROP,
  SECTION_DRAG_DROP_SUCCESS,
  SECTION_DRAG_DROP_ERROR,
  AB_DETAIL_ADD_SECTION,
  AB_DETAIL_ADD_SECTION_SUCCESS,
  AB_DETAIL_ADD_SECTION_ERROR,
  SET_AB_LIBRARY_FOLDER_ID,
  AB_SAVE_CUSTOM_RESTORE,
  AB_SAVE_CUSTOM_RESTORE_SUCCESS,
  AB_SAVE_CUSTOM_RESTORE_ERROR,
  FETCH_RESTORE_POINT_LISTING,
  FETCH_RESTORE_POINT_LISTING_SUCCESS,
  FETCH_RESTORE_POINT_LISTING_ERROR,
  FETCH_AB_CATEGORIES_VOLUME_LIST,
  FETCH_AB_CATEGORIES_VOLUME_LIST_SUCCESS,
  FETCH_AB_CATEGORIES_VOLUME_LIST_ERROR,
  FETCH_AB_RESTORE_LIST,
  FETCH_AB_RESTORE_LIST_SUCCESS,
  FETCH_AB_RESTORE_LIST_ERROR,
  FETCH_AB_TEACHING_ELEMENTS,
  FETCH_AB_TEACHING_ELEMENTS_SUCCESS,
  FETCH_AB_TEACHING_ELEMENTS_ERROR,
  SET_SIZE_PER_PAGE_AB_CUSTOM_BUILD_LIST,
  APP_BUILDER_ACTION_UNDO,
  APP_BUILDER_ACTION_REDO,
  APP_BUILDER_BUILD_CHANGE,
  APP_BUILDER_VOLUME_ACTION_UNDO,
  APP_BUILDER_VOLUME_ACTION_REDO,
  APP_BUILDER_VOLUME_BUILD_CHANGE,
  APP_BUILD_REMOVE,
  STATE_ACTION_APP,
  CHANGE_APP_ELEMENTS,
  IS_DUPLICATE,
  CHANGE_VOLUME_ELEMENTS,
} from "./actionTypes";

const ABElementsInitialState = {
  ABExportElements: [],
  RestorePoint: [],
  abExpportLoading: false,
  ABCustomBuildList: [],
  LibraryBuildListing: [],
  shareMBLink: "",
  shareABLink: "",
  loading: false,
  search: "",
  sizePerPageAB: 50,
  pageNoAB: 1,
  sortField: "volume_id",
  sortOrder: "DESC",
  totalSizeAB: 0,
  isDuplicateSeries: false,
  addSeriesImport: false,
  SeriesDetail: [],
  SeriesSave: [],
  SeriesElements: [],
  AddSeries: null,
  seriesChange: false,
  elementDuplicate: false,
  seriesSelectionLoading: false,
  addSeriesLoading: false,
  addDuplicateSeriesLoading: 0,
  sizePerPage: 50,
  page: 1,
  // eslint-disable-next-line no-dupe-keys
  sortField: "message_build_list_id",
  // sortOrder: "DESC",
  totalSize: 0,
  ABVolumeLength: 0,
  SeriesByCategoriesVolume: [],
  saveSeriesDescLoading: false,
  StoreAppFolderId: 0,
  CategoriesVolumeList: [],
  ABRestoreList: [],
  ABTeachingElements: [],
  undo: [],
  redo: [],
  undoVolume: [],
  redoVolume: [],
  stateAction: "",
  stateActionApp: "",
};

const types = {
  1: "kids",
  2: "students",
  3: "groups",
};

const ABElementReducer = (state = ABElementsInitialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case AB_VOLUME_LENGTH:
      return {
        ...state,
        ABVolumeLength: action.payload,
      };
    case AB_RESET_SERIES_DATA:
      return {
        ...state,
        SeriesElements: [],
        SeriesDetail: [],
      };
    /** SeriesDetail list */
    case FETCH_APP_SERIES_LISTING:
      return {
        ...state,
        // loading: true,
      };
    case FETCH_APP_SERIES_LISTING_SUCCESS:
      return {
        ...state,
        SeriesDetail: action.payload,
        undo:
          state.undo.length === 0
            ? [[state.SeriesElements, payload]]
            : [...state.undo, [state.SeriesElements, payload]],
        redo: state.redo.length ? [] : clonedeep(state.redo),
        loading: false,
        stateAction: "series",
        totalSize: payload.count,
      };
    case FETCH_APP_SERIES_LISTING_ERROR:
      return {
        ...state,
        loading: false,
      };

    //Series Save
    case SAVE_APP_VOLUME_DATA:
      return {
        ...state,
        seriesChange: true,
        loading: true,
      };
    case SAVE_APP_VOLUME_DATA_SUCCESS:
      return {
        ...state,
        SeriesSave: action.payload,
        seriesChange: false,
        loading: false,
      };
    case SAVE_APP_VOLUME_DATA_ERROR:
      return {
        ...state,
        loading: false,
      };

    //Series Title Save
    case SAVE_APP_SERIES_TITLE:
      return {
        ...state,
        seriesChange: true,
        abSectionLoading: true,
        loading: true,
      };
    case SAVE_APP_SERIES_TITLE_SUCCESS:
      return {
        SeriesSave: action.payload,
        ...state,
        seriesChange: false,
        abSectionLoading: false,
        loading: true,
      };
    case SAVE_APP_SERIES_TITLE_ERROR:
      return {
        ...state,
        loading: true,
      };
    //SeriesDetail DUPLICATE
    case SERIES_DETAIL_DUPLICATE:
      return {
        ...state,
        elementDuplicate: true,
        addDuplicateSeriesLoading: 2,
        loading: false,
      };
    case SERIES_DETAIL_DUPLICATE_SUCCESS:
      return {
        ...state,
        elementDuplicate: false,
        addDuplicateSeriesLoading: 0,
        loading: true,
      };
    case SERIES_DETAIL_DUPLICATE_ERROR:
      return {
        ...state,
        addDuplicateSeriesLoading: 0,
        loading: true,
      };

    //SeriesDetail share link
    case SERIES_DETAIL_SHARE_LINK:
      return {
        ...state,
        loading: true,
      };
    case SERIES_DETAIL_SHARE_LINK_SUCCESS:
      return {
        ...state,
        loading: true,
      };
    case SERIES_DETAIL_SHARE_LINK_ERROR:
      return {
        ...state,
        loading: true,
      };

    //SeriesDetail delete
    case SERIES_DETAIL_DELETE:
      return {
        ...state,
      };
    case SERIES_DETAIL_DELETE_SUCCESS:
      return {
        ...state,
      };
    case SERIES_DETAIL_DELETE_ERROR:
      return {
        ...state,
      };

    /** SeriesElement list */
    case FETCH_SERIES_ELEMENT_LISTING:
      return {
        ...state,
        seriesSelectionLoading: true,
      };
    case FETCH_SERIES_ELEMENT_LISTING_SUCCESS:
      return {
        ...state,
        SeriesElements: action.payload,
        undo:
          state.undo.length === 0
            ? [[payload, state.SeriesDetail]]
            : [...state.undo, [payload, state.SeriesDetail]],
        redo: state.redo.length ? [] : clonedeep(state.redo),
        seriesSelectionLoading: false,
        totalSize: payload.count,
      };
    case FETCH_SERIES_ELEMENT_LISTING_ERROR:
      return {
        ...state,
        seriesSelectionLoading: false,
      };

    /** Add Series */
    case ADD_SERIES:
      return {
        ...state,
        addSeriesLoading: true,
        addDuplicateSeriesLoading: 1,
      };
    case ADD_SERIES_SUCCESS:
      return {
        ...state,
        AddSeries: action.payload,
        addSeriesLoading: false,
        addDuplicateSeriesLoading: 0,
        totalSize: payload.count,
      };
    case ADD_SERIES_ERROR:
      return {
        ...state,
        addDuplicateSeriesLoading: 0,
        loading: false,
      };

    /** series drag drop */
    case SERIES_DRAG_DROP:
      return {
        ...state,
        loading: false,
      };
    case SERIES_DRAG_DROP_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case SERIES_DRAG_DROP_ERROR:
      return {
        ...state,
        loading: false,
      };
    //deleting element from redux
    case SET_AB_SECTION:
      return {
        ...state,
        //deleting element from redux
        SeriesElements: {
          ...state.SeriesElements,
          // eslint-disable-next-line eqeqeq
          ...(payload.elementId && payload.isDeleteSeries == 1
            ? {
                sycu_build_elements_details:
                  state.SeriesElements.sycu_build_elements_details.filter(
                    item => item.build_elements_details_id !== payload.elementId
                  ),
              }
            : {}),
        },
      };

    //deleting SERIES from redux
    case SET_AB_SERIES:
      return {
        ...state,
        SeriesDetail: {
          ...state.SeriesDetail,
          ...(payload.elementId
            ? {
                gb_series_buildlists:
                  state.SeriesDetail.gb_series_buildlists.filter(
                    item => item.series_build_list_id !== payload.elementId
                  ),
              }
            : {}),
        },
        undo:
          state.undo.length === 0
            ? [[state.SeriesElements, state.SeriesDetail]]
            : [
                ...state.undo,
                [
                  state.SeriesElements,
                  {
                    ...state.SeriesDetail,
                    ...(payload.elementId
                      ? {
                          gb_series_buildlists:
                            state.SeriesDetail.gb_series_buildlists.filter(
                              item =>
                                item.series_build_list_id !== payload.elementId
                            ),
                        }
                      : {}),
                  },
                ],
              ],
        redo: state.redo.length ? [] : clonedeep(state.redo),
      };

    //On/Off Slider App Elements
    case SET_VISIBLE_APP_UPDATE:
      return {
        ...state,
        SeriesElements: {
          ...state.SeriesElements,
          ...state.SeriesElements.sycu_build_elements_details.filter(item =>
            item.build_elements_details_id === payload.id
              ? (item.is_visible = payload.sts)
              : item
          ),
        },
      };

    /**App Builder Series Import*/
    case FETCH_AB_SERIES_IMPORT:
      return {
        ...state,
        loading: true,
        addSeriesImport: true,
      };
    case FETCH_AB_SERIES_IMPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        addSeriesImport: false,
      };
    case FETCH_AB_SERIES_IMPORT_ERROR:
      return {
        ...state,
        loading: false,
      };
    /** App Builder Export list */
    case FETCH_AB_EXPORT_ELEMENTS:
      return {
        ...state,
        abExpportLoading: true,
      };
    case FETCH_AB_EXPORT_ELEMENTS_SUCCESS:
      return {
        ...state,
        abExpportLoading: false,
        ABExportElements: action.payload,
        totalSize: payload.length,
      };
    case FETCH_AB_EXPORT_ELEMENTS_ERROR:
      return {
        ...state,
        abExpportLoading: false,
      };
    case RESET_AB_CUSTOM_BUILD_LIST:
      return {
        ...state,
        ABCustomBuildList: [],
      };
    /** AB list */
    case FETCH_AB_CUSTOM_BUILD_LIST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_AB_CUSTOM_BUILD_LIST_SUCCESS:
      return {
        ...state,
        ABCustomBuildList: [...state.ABCustomBuildList, ...payload.data],
        loading: false,
        totalSizeAB: payload.count,
      };
    case FETCH_AB_CUSTOM_BUILD_LIST_ERROR:
      return {
        ...state,
        loading: false,
      };

    /** AB CREATE VOLUME */
    case AB_CREATE_VOLUME:
      return {
        ...state,
        loading: true,
      };
    case AB_CREATE_VOLUME_SUCCESS:
      return {
        ...state,
        // ABCustomBuildList: action.payload,
        loading: false,
        totalSizeAB: payload.count,
      };
    case AB_CREATE_VOLUME_ERROR:
      return {
        ...state,
        loading: false,
      };

    //AB delete
    case AB_DELETE:
      return {
        ...state,
        loading: true,
      };
    case AB_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case AB_DELETE_ERROR:
      return {
        ...state,
        loading: false,
      };
    //AB DUPLICATE
    case AB_DUPLICATE:
      return {
        ...state,
        loading: payload,
      };
    case AB_DUPLICATE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case AB_DUPLICATE_ERROR:
      return {
        ...state,
        loading: false,
      };
    /** Get library build */
    case GET_LIBRARY_BUILD:
      return {
        ...state,
        loading: true,
      };
    case GET_LIBRARY_BUILD_SUCCESS:
      return {
        ...state,
        loading: false,
        LibraryBuildListing: action.payload,
      };
    case GET_LIBRARY_BUILD_ERROR:
      return {
        ...state,
        loading: false,
      };
    // Set PageNo Of AB Custom Build List
    case SET_PAGE_AB_CUSTOM_BUILD_LIST:
      return {
        ...state,
        pageNoAB: payload,
      };
    // Set Size per page Of AB Custom Build List
    case SET_SIZE_PER_PAGE_AB_CUSTOM_BUILD_LIST:
      return {
        ...state,
        sizePerPage: payload,
      };
    //AB share link
    case AB_SHARE_LINK:
      return {
        ...state,
        loading: true,
      };
    case AB_SHARE_LINK_SUCCESS:
      return {
        ...state,
        shareABLink: action.payload.shareable_link,
        loading: false,
      };
    case AB_SHARE_LINK_ERROR:
      return {
        ...state,
        loading: true,
      };

    //deleting VOLUME from redux
    case SET_AB_VOLUME:
      return {
        ...state,
        //deleting VOLUME from redux
        ABCustomBuildList: state.ABCustomBuildList.filter(
          // eslint-disable-next-line eqeqeq
          item => item.volume_id != payload.elementId.volume_id
        ),
      };
    //deleting VOLUME from redux
    case SET_AB_LIBRARY:
      return {
        ...state,
        LibraryBuildListing: {
          ...state.LibraryBuildListing,
          [types[payload.elementMinistry]]: state.LibraryBuildListing[
            types[payload.elementMinistry]
          ].filter(i => i.volume_id !== payload.elementId.volume_id),
        },
      };
    /**App Builder Details Import Series By Categories Volume*/
    case FETCH_AB_SERIES_BY_CATEGORIES_VOLUME:
      return {
        ...state,
        loading: true,
      };
    case FETCH_AB_SERIES_BY_CATEGORIES_VOLUME_SUCCESS:
      return {
        ...state,
        SeriesByCategoriesVolume: action.payload,
        loading: false,
      };
    case FETCH_AB_SERIES_BY_CATEGORIES_VOLUME_ERROR:
      return {
        ...state,
        loading: false,
      };
    /** Sections Image DropZone Element  */
    case IMAGE_SECTIONS:
      return {
        ...state,
        loading: true,
        saveSeriesDescLoading: true,
      };
    case IMAGE_SECTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        saveSeriesDescLoading: false,
      };
    case IMAGE_SECTIONS_ERROR:
      return {
        ...state,
        loading: false,
        saveSeriesDescLoading: false,
      };
    /** Section drag drop */
    case SECTION_DRAG_DROP:
      return {
        ...state,
        loading: true,
      };
    case SECTION_DRAG_DROP_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case SECTION_DRAG_DROP_ERROR:
      return {
        ...state,
        loading: false,
      };
    //AB DETAILS ADD SECTION
    case AB_DETAIL_ADD_SECTION:
      return {
        ...state,
        abSectionLoading: true,
      };
    case AB_DETAIL_ADD_SECTION_SUCCESS:
      return {
        ...state,
        abSectionLoading: false,
      };
    case AB_DETAIL_ADD_SECTION_ERROR:
      return {
        ...state,
        loading: false,
        abSectionLoading: false,
      };
    /*Store Library Folder ID */
    case SET_AB_LIBRARY_FOLDER_ID:
      return {
        ...state,
        StoreAppLibraryFolderId: action.payload,
        StoreAppFolderId: 0,
        loading: false,
      };
    //APP Builder save Custom Restore on change
    case AB_SAVE_CUSTOM_RESTORE:
      return {
        ...state,
        loading: true,
      };
    case AB_SAVE_CUSTOM_RESTORE_SUCCESS:
      return {
        ...state,
        loading: true,
      };
    case AB_SAVE_CUSTOM_RESTORE_ERROR:
      return {
        ...state,
        loading: false,
      };
    /** Restore Point list */
    case FETCH_RESTORE_POINT_LISTING:
      return {
        ...state,
        loading: true,
      };
    case FETCH_RESTORE_POINT_LISTING_SUCCESS:
      return {
        ...state,
        RestorePoint: action.payload,
        loading: false,
        totalSize: payload.count,
      };
    case FETCH_RESTORE_POINT_LISTING_ERROR:
      return {
        ...state,
        loading: false,
      };
    /**App Builder Details Import Categories Volume*/
    case FETCH_AB_CATEGORIES_VOLUME_LIST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_AB_CATEGORIES_VOLUME_LIST_SUCCESS:
      return {
        ...state,
        CategoriesVolumeList: action.payload,
        loading: false,
      };
    case FETCH_AB_CATEGORIES_VOLUME_LIST_ERROR:
      return {
        ...state,
        loading: false,
      };
    //AB Elements restore Listing
    case FETCH_AB_RESTORE_LIST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_AB_RESTORE_LIST_SUCCESS:
      return {
        ...state,
        ABRestoreList: action.payload,
        loading: false,
      };
    case FETCH_AB_RESTORE_LIST_ERROR:
      return {
        ...state,
        loading: false,
      };
    /** App Builder Teaching list */
    case FETCH_AB_TEACHING_ELEMENTS:
      return {
        ...state,
        loading: true,
      };
    case FETCH_AB_TEACHING_ELEMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        ABTeachingElements: action.payload,
        SeriesElements: action.payload,
        totalSize: payload.length,
      };
    case FETCH_AB_TEACHING_ELEMENTS_ERROR:
      return {
        ...state,
        loading: false,
      };
    case APP_BUILDER_ACTION_UNDO:
      const newUndoState = state.undo;
      const addToRedo = newUndoState.pop();
      const newBuilds = newUndoState[newUndoState.length - 1];
      return {
        ...state,
        redo: [clonedeep(addToRedo), ...clonedeep(state.redo)],
        SeriesElements: newBuilds[0],
        SeriesDetail: newBuilds[1],
      };

    case APP_BUILDER_ACTION_REDO:
      const newRedoState = state.redo;
      const addToUndo = newRedoState.shift();
      return {
        ...state,
        undo: [...state.undo, addToUndo],
        redo: newRedoState,
        SeriesElements: clonedeep(addToUndo[0]),
        SeriesDetail: clonedeep(addToUndo[1]),
      };

    case APP_BUILD_REMOVE:
      return {
        ...state,
        undo: [],
        redo: [],
      };
    case APP_BUILDER_BUILD_CHANGE:
      return {
        ...state,
        undo:
          state.undo.length === 0
            ? [[payload, state.SeriesDetail]]
            : [...state.undo, [payload, state.SeriesDetail]],
        redo: state.redo.length ? [] : clonedeep(state.redo),
        stateAction: "series",
      };

    case APP_BUILDER_VOLUME_ACTION_UNDO:
      const newUndoVolumeState = state.undoVolume;
      const addToRedoVolume = newUndoVolumeState.pop();
      const newBuildsVolume = newUndoVolumeState[newUndoVolumeState.length - 1];
      return {
        ...state,
        redoVolume: [
          clonedeep(addToRedoVolume),
          ...clonedeep(state.redoVolume),
        ],
        SeriesDetail: newBuildsVolume,
      };

    case APP_BUILDER_VOLUME_ACTION_REDO:
      const newRedoVolumeState = state.redoVolume;
      const addToUndoVolume = newRedoVolumeState.shift();
      return {
        ...state,
        undoVolume: [...state.undoVolume, addToUndoVolume],
        redoVolume: newRedoVolumeState,
        SeriesDetail: clonedeep(addToUndoVolume),
      };
    case APP_BUILDER_VOLUME_BUILD_CHANGE:
      return {
        ...state,
        undo:
          state.undo.length === 0
            ? [[state.SeriesElements, payload]]
            : [...state.undo, [state.SeriesElements, payload]],
        redo: state.redo.length ? [] : clonedeep(state.redo),
        stateAction: "series",
      };

    case STATE_ACTION_APP:
      return {
        ...state,
        stateActionApp: payload,
      };
    case CHANGE_APP_ELEMENTS:
      return {
        ...state,
        SeriesElements: {
          ...state.SeriesElements,
          series_buildlist_title: payload.series_buildlist_title,
          series_buildlist_content: payload.series_buildlist_content,
          ...state.SeriesElements?.sycu_build_elements_details?.map(item =>
            item.build_elements_details_id === payload.id
              ? ((item.content = payload.content), (item.title = payload.title))
              : item
          ),
        },
      };
    case CHANGE_VOLUME_ELEMENTS:
      return {
        ...state,
        SeriesDetail: {
          ...state.SeriesDetail,
          volume_title: payload,
        },
      };
    case IS_DUPLICATE:
      return {
        ...state,
        isDuplicateSeries: payload,
      };
    default:
      return state;
  }
};
export default ABElementReducer;
