export const FETCH_USER_FOLDER = "FETCH_USER_FOLDER";
export const FETCH_USER_FOLDER_SUCCESS = "FETCH_USER_FOLDER_SUCCESS";
export const FETCH_USER_FOLDER_ERROR = "FETCH_USER_FOLDER_ERROR";
export const FETCH_USER_FOLDER_LOADING = "FETCH_USER_FOLDER_LOADING";

//Add Edit Folder
export const FOLDER_ADD_EDIT = "FOLDER_ADD_EDIT";
export const FOLDER_ADD_EDIT_SUCCESS = "FOLDER_ADD_EDIT_SUCCESS";
export const FOLDER_ADD_EDIT_ERROR = "FOLDER_ADD_EDIT_ERROR";

//Delete Folder
export const FOLDER_DELETE = "FOLDER_DELETE";
export const FOLDER_DELETE_SUCCESS = "FOLDER_DELETE_SUCCESS";
export const FOLDER_DELETE_ERROR = "FOLDER_DELETE_ERROR";

//Folder Search
export const FOLDER_SEARCH = "FOLDER_SEARCH";
export const FOLDER_SEARCH_SUCCESS = "FOLDER_SEARCH_SUCCESS";
export const FOLDER_SEARCH_ERROR = "FOLDER_SEARCH_ERROR";

/** move lesson builder to folder */
export const MOVE_BUILD_TO_FOLDER = "MOVE_BUILD_TO_FOLDER";
export const MOVE_BUILD_TO_FOLDER_SUCCESS = "MOVE_BUILD_TO_FOLDER_SUCCESS";
export const MOVE_BUILD_TO_FOLDER_ERROR = "MOVE_BUILD_TO_FOLDER_ERROR";

// Share lesson builder
export const SET_INVITE_LB_DATA = "SET_INVITE_LB_DATA";

// Share app builder
export const SET_INVITE_AB_DATA = "SET_INVITE_AB_DATA";

// SideBar Key
export const SET_SIDEBAR_KEY = "SET_SIDEBAR_KEY";
