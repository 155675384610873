import {
  BUILDER_AUTO_SAVE_LOADING,
  FETCH_MB_CUSTOM_BUILD_LIST,
  FETCH_MB_CUSTOM_BUILD_LIST_ERROR,
  FETCH_MB_CUSTOM_BUILD_LIST_SUCCESS,
  GET_MB_FOLDER_BUILD_LIST,
  GET_MB_FOLDER_BUILD_LIST_ERROR,
  GET_MB_FOLDER_BUILD_LIST_SUCCESS,
  RESET_MB_FOLDER_BUILD_LIST,
} from "./actionTypes";

const MBInitialState = {
  MBCustomBuildList: [],
  MBFolderBuildList: [],
  MBShareLink: [],
  loading: false,
  search: "",
  sizePerPageMB: 10,
  pageNoMB: 1,
  sortField: "message_build_list_id",
  sortOrder: "DESC",
  totalSizeMB: 0,
  autoSaveElementLoading: false,
};

const MBReducer = (state = MBInitialState, action) => {
  const { type, payload } = action;
  switch (type) {
    /* Reset MBFolder Build List */
    case RESET_MB_FOLDER_BUILD_LIST:
      return {
        ...state,
        MBFolderBuildList: [],
      };

    /** MB list */
    case FETCH_MB_CUSTOM_BUILD_LIST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_MB_CUSTOM_BUILD_LIST_SUCCESS:
      return {
        ...state,
        MBCustomBuildList: [...state.MBCustomBuildList, ...payload.data],
        loading: false,
        totalSizeMB: payload.count,
      };
    case FETCH_MB_CUSTOM_BUILD_LIST_ERROR:
      return {
        ...state,
        loading: false,
      };
    /** Get builder by folder id */
    case GET_MB_FOLDER_BUILD_LIST:
      return {
        ...state,
        loading: true,
      };
    case GET_MB_FOLDER_BUILD_LIST_SUCCESS:
      return {
        ...state,
        MBFolderBuildList: [...state.MBFolderBuildList, ...payload.data],
        StoreLibraryFolderId: "",
        loading: false,
        totalSizeMBFolder: payload.count,
      };
    case GET_MB_FOLDER_BUILD_LIST_ERROR:
      return {
        ...state,
        loading: true,
      };
    case BUILDER_AUTO_SAVE_LOADING:
      return {
        ...state,
        autoSaveElementLoading: payload,
      };
    default:
      return state;
  }
};
export default MBReducer;
