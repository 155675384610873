import clonedeep from "lodash.clonedeep";

import {
  FETCH_MB_CUSTOM_BUILD_LIST,
  FETCH_MB_CUSTOM_BUILD_LIST_SUCCESS,
  FETCH_MB_CUSTOM_BUILD_LIST_ERROR,
  SET_PAGE_MB_CUSTOM_BUILD_LIST,
  MB_DUPLICATE,
  MB_DUPLICATE_SUCCESS,
  MB_DUPLICATE_ERROR,
  MB_SHARE_LINK,
  MB_SHARE_LINK_SUCCESS,
  MB_SHARE_LINK_ERROR,
  MB_DELETE,
  MB_DELETE_SUCCESS,
  MB_DELETE_ERROR,
  MB_UPLOAD_BUILD_IMAGE,
  MB_UPLOAD_BUILD_IMAGE_SUCCESS,
  MB_UPLOAD_BUILD_IMAGE_ERROR,
  SET_MB_LISTING,
  RESET_MB_CUSTOM_BUILD_LIST,
  SAVE_ON_ELEMENT_CHANGE,
  SAVE_ON_ELEMENT_CHANGE_SUCCESS,
  SAVE_ON_ELEMENT_CHANGE_ERROR,
  MB_DELETE_CUSTOM_RESTORE,
  MB_DELETE_CUSTOM_RESTORE_SUCCESS,
  MB_DELETE_CUSTOM_RESTORE_ERROR,
  FETCH_MB_RESTORE_LIST,
  FETCH_MB_RESTORE_LIST_SUCCESS,
  FETCH_MB_RESTORE_LIST_ERROR,
  SAVE_ON_CHANGE,
  SAVE_ON_CHANGE_SUCCESS,
  SAVE_ON_CHANGE_ERROR,
  MB_SAVE_CUSTOM_RESTORE,
  MB_SAVE_CUSTOM_RESTORE_SUCCESS,
  MB_SAVE_CUSTOM_RESTORE_ERROR,
  FETCH_MB_EXPORT_ELEMENTS,
  FETCH_MB_EXPORT_ELEMENTS_SUCCESS,
  FETCH_MB_EXPORT_ELEMENTS_ERROR,
  MESSAGE_ELEMENT_DRAG_DROP,
  MESSAGE_ELEMENT_DRAG_DROP_SUCCESS,
  MESSAGE_ELEMENT_DRAG_DROP_ERROR,
  DRAG_BUILDING_BLOCK,
  DRAG_BUILDING_BLOCK_SUCCESS,
  DRAG_BUILDING_BLOCK_ERROR,
  GET_ACTIVE_SUBSCRIPTION_LIST,
  FETCH_MB_ELEMENTS,
  FETCH_MB_ELEMENTS_SUCCESS,
  FETCH_MB_ELEMENTS_ERROR,
  FETCH_BUILDING_BLOCKS_LISTING,
  FETCH_BUILDING_BLOCKS_LISTING_SUCCESS,
  FETCH_BUILDING_BLOCKS_LISTING_ERROR,
  MB_DELETE_ELEMENT,
  MB_DELETE_ELEMENT_SUCCESS,
  MB_DELETE_ELEMENT_ERROR,
  MB_DUPLICATE_ELEMENT,
  MB_DUPLICATE_ELEMENT_SUCCESS,
  MB_DUPLICATE_ELEMENT_ERROR,
  SET_VISIBLE_UPDATE,
  MESSAGE_VIDEO_ELEMENT,
  MESSAGE_VIDEO_ELEMENT_SUCCESS,
  MESSAGE_VIDEO_ELEMENT_ERROR,
  BB_SEARCH,
  BB_SEARCH_SUCCESS,
  BB_SEARCH_ERROR,
  SET_MB_TEACHING_ELEMENTS,
  FETCH_MB_TEACHING_ELEMENTS_SUCCESS,
  FETCH_MB_TEACHING_ELEMENTS_ERROR,
  FETCH_MB_TEACHING_ELEMENTS_BY_CODE,
  FETCH_MB_TEACHING_ELEMENTS_BY_CODE_SUCCESS,
  FETCH_MB_TEACHING_ELEMENTS_BY_CODE_ERROR,
  BUILDER_ACTION_UNDO,
  BUILDER_ACTION_REDO,
  BUILDER_BUILD_CHANGE,
  BUILD_TITLE_CHANGE,
  BUILD_SUB_TITLE_CHANGE,
  STATE_ACTION,
  CLEAR_UNDO_REDO_STATE,
  CHANGE_MESSAGE_ELEMENT,
  RIGHT_BAR_SCROLL,
  SET_INVITED_USERS,
  FETCH_MB_ELEMENTS_LOADER,
} from "./actionTypes";

const MBInitialState = {
  MBCustomBuildList: [],
  MBElements: "",
  MBShareLink: [],
  loading: false,
  search: "",
  sizePerPageMB: 10,
  pageNoMB: 1,
  sortField: "message_build_list_id",
  sortOrder: "DESC",
  totalSizeMB: 0,
  MBRestoreList: [],
  MBExportElements: [],
  mbExpportLoading: false,
  activeSubscriptionsList: [],
  BuildingBlockListing: [],
  deleteLoading: false,
  duplicateLoading: false,
  MBTeachingElements: [],
  userMessageCount: 0,
  userVolumeCount: 0,
  systemMessageCount: 0,
  systemVolumeCount: 0,
  right_bar_scroll: false,
  // stack: {
  //   undo: [],
  //   redo: [],
  // },
  undo: [],
  redo: [],
  stateAction: "",
  invitedUsers: [],
  autoSaveElementLoading: false,
  elementLoaderA: false,
};

const MBReducer = (state = MBInitialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_INVITED_USERS:
      return {
        ...state,
        invitedUsers: payload,
      };
    /** MB list */
    case FETCH_MB_ELEMENTS:
      return {
        ...state,
        loading: payload,
      };
    case FETCH_MB_ELEMENTS_LOADER:
      return {
        ...state,
        elementLoaderA: payload,
      };
    case FETCH_MB_ELEMENTS_SUCCESS:
      return {
        ...state,
        MBElements: action.payload,
        // undo:action.payload,
        undo: state.undo.length === 0 ? [payload] : [...state.undo, payload],
        redo: state.redo.length ? [] : clonedeep(state.redo),
        loading: false,
        // elementLoaderA: false,
      };
    case FETCH_MB_ELEMENTS_ERROR:
      return {
        ...state,
        loading: false,
      };
    /** MB Teaching list */
    case SET_MB_TEACHING_ELEMENTS:
      return {
        ...state,
        loading: true,
      };
    case FETCH_MB_TEACHING_ELEMENTS_SUCCESS:
      return {
        ...state,
        MBTeachingElements: action.payload,
        loading: false,
      };
    case FETCH_MB_TEACHING_ELEMENTS_ERROR:
      return {
        ...state,
        loading: false,
      };

    /** App Builder Teaching list By Code **/
    case FETCH_MB_TEACHING_ELEMENTS_BY_CODE:
      return {
        ...state,
        loading: true,
      };

    case FETCH_MB_TEACHING_ELEMENTS_BY_CODE_SUCCESS:
      return {
        ...state,
        loading: false,
        MBTeachingElements: action.payload,
        MBElements: action.payload,
        totalSize: payload.length,
      };

    case FETCH_MB_TEACHING_ELEMENTS_BY_CODE_ERROR:
      return {
        ...state,
        loading: false,
      };
    /*Reset MBCustom Build List */
    case RESET_MB_CUSTOM_BUILD_LIST:
      return {
        ...state,
        MBCustomBuildList: [],
      };
    /** MB list */
    case FETCH_MB_CUSTOM_BUILD_LIST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_MB_CUSTOM_BUILD_LIST_SUCCESS:
      return {
        ...state,
        MBCustomBuildList: [...state.MBCustomBuildList, ...payload.data],
        loading: false,
        totalSizeMB: payload.count,
      };
    case FETCH_MB_CUSTOM_BUILD_LIST_ERROR:
      return {
        ...state,
        loading: false,
      };

    //MB save on ELEMENT change
    case SAVE_ON_ELEMENT_CHANGE:
      return {
        ...state,
        autoSaveElementLoading: true,
      };
    case SAVE_ON_ELEMENT_CHANGE_SUCCESS:
      return {
        ...state,
        saveLoading: false,
      };
    case SAVE_ON_ELEMENT_CHANGE_ERROR:
      return {
        ...state,
        saveLoading: false,
      };

    //Set MbCustom List PageNo
    case SET_PAGE_MB_CUSTOM_BUILD_LIST:
      return {
        ...state,
        pageNoMB: payload,
      };

    //MB DUPLICATE
    case MB_DUPLICATE:
      return {
        ...state,
        loading: true,
      };
    case MB_DUPLICATE_SUCCESS:
      return {
        ...state,
        loading: true,
      };
    case MB_DUPLICATE_ERROR:
      return {
        ...state,
        loading: true,
      };
    //MB share link
    case MB_SHARE_LINK:
      return {
        ...state,
        loading: true,
      };
    case MB_SHARE_LINK_SUCCESS:
      return {
        ...state,
        MBShareLink: action.payload.shareable_link,
        loading: true,
      };
    case MB_SHARE_LINK_ERROR:
      return {
        ...state,
        loading: true,
      };

    //MB Upload Build Media
    case MB_UPLOAD_BUILD_IMAGE:
      return {
        ...state,
        loading: true,
      };
    case MB_UPLOAD_BUILD_IMAGE_SUCCESS:
      return {
        ...state,
        MBShareLink: action.payload,
        loading: true,
      };
    case MB_UPLOAD_BUILD_IMAGE_ERROR:
      return {
        ...state,
        loading: true,
      };

    //MB delete
    case MB_DELETE:
      return {
        ...state,
        loading: true,
      };
    case MB_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case MB_DELETE_ERROR:
      return {
        ...state,
        loading: false,
      };

    //deleting MB MESSAGE from redux
    case SET_MB_LISTING:
      return {
        ...state,
        MBCustomBuildList: [
          ...state.MBCustomBuildList.filter(
            item =>
              item.message_build_list_id !==
              payload.elementId.message_build_list_id
          ),
        ],
      };
    case FETCH_MB_RESTORE_LIST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_MB_RESTORE_LIST_SUCCESS:
      return {
        ...state,
        MBRestoreList: action.payload,
        loading: false,
      };
    case FETCH_MB_RESTORE_LIST_ERROR:
      return {
        ...state,
        loading: false,
      };
    //Message Builder delete Manual Restore Point
    case MB_DELETE_CUSTOM_RESTORE:
      return {
        ...state,
        loading: true,
      };
    case MB_DELETE_CUSTOM_RESTORE_SUCCESS:
      return {
        ...state,
        loading: true,
      };
    case MB_DELETE_CUSTOM_RESTORE_ERROR:
      return {
        ...state,
        loading: true,
      };
    //MB save on change
    case SAVE_ON_CHANGE:
      return {
        ...state,
        saveLoading: true,
      };
    case SAVE_ON_CHANGE_SUCCESS:
      return {
        ...state,
        MBElements: action.payload,
        saveLoading: false,
      };
    case SAVE_ON_CHANGE_ERROR:
      return {
        ...state,
        saveLoading: true,
      };
    //Message Builder save Custom Restore on change
    case MB_SAVE_CUSTOM_RESTORE:
      return {
        ...state,
        loading: true,
      };
    case MB_SAVE_CUSTOM_RESTORE_SUCCESS:
      return {
        ...state,
        loading: true,
      };
    case MB_SAVE_CUSTOM_RESTORE_ERROR:
      return {
        ...state,
        loading: true,
      };
    //MB Eexport
    case FETCH_MB_EXPORT_ELEMENTS:
      return {
        ...state,
        mbExpportLoading: true,
      };
    case FETCH_MB_EXPORT_ELEMENTS_SUCCESS:
      return {
        ...state,
        mbExpportLoading: false,
        MBExportElements: action.payload,
        totalSize: payload.length,
      };
    case FETCH_MB_EXPORT_ELEMENTS_ERROR:
      return {
        ...state,
        mbExpportLoading: true,
      };
    //message element drag drop
    case MESSAGE_ELEMENT_DRAG_DROP:
      return {
        ...state,
        loading: true,
      };
    case MESSAGE_ELEMENT_DRAG_DROP_SUCCESS:
      return {
        ...state,
        loading: true,
      };
    case MESSAGE_ELEMENT_DRAG_DROP_ERROR:
      return {
        ...state,
        loading: true,
      };
    /** MB building blocks list */
    case FETCH_BUILDING_BLOCKS_LISTING:
      return {
        ...state,
        loading: true,
      };
    case FETCH_BUILDING_BLOCKS_LISTING_SUCCESS:
      return {
        ...state,
        BuildingBlockListing: action.payload,
      };
    case FETCH_BUILDING_BLOCKS_LISTING_ERROR:
      return {
        ...state,
        loading: true,
      };
    /** Building Block Searching */
    case BB_SEARCH:
      return {
        ...state,
        loading: true,
        fetchFolderLoading: true,
      };
    case BB_SEARCH_SUCCESS:
      return {
        ...state,
        loading: false,
        BuildingBlockListing: [
          ...state.BuildingBlockListing.filter(
            item =>
              item?.build_elements_name
                .slice(0, action.payload.length)
                .toLowerCase() === action.payload.toLowerCase()
          ),
        ],
      };
    case BB_SEARCH_ERROR:
      return {
        ...state,
        loading: true,
      };
    //building block drag drop
    case DRAG_BUILDING_BLOCK:
      return {
        ...state,
        loading: true,
      };
    case DRAG_BUILDING_BLOCK_SUCCESS:
      return {
        ...state,
        loading: true,
      };
    case DRAG_BUILDING_BLOCK_ERROR:
      return {
        ...state,
        loading: true,
      };
    case GET_ACTIVE_SUBSCRIPTION_LIST:
      return {
        ...state,
        loading: false,
        activeSubscriptionsList: payload.rows,
        systemMessageCount: payload.is_system_message_count,
        systemVolumeCount: payload.is_system_volume_count,
        userMessageCount: payload.is_user_message_count,
        userVolumeCount: payload.is_user_volume_count,
      };
    // state.activeSubscriptionsList = payload;
    // break;
    //MB delete element
    case MB_DELETE_ELEMENT:
      return {
        ...state,
        deleteLoading: true,
      };
    case MB_DELETE_ELEMENT_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case MB_DELETE_ELEMENT_ERROR:
      return {
        ...state,
        deleteLoading: true,
      };
    //MB ELEMENT DUPLICATE
    case MB_DUPLICATE_ELEMENT:
      return {
        ...state,
        duplicateLoading: true,
      };
    case MB_DUPLICATE_ELEMENT_SUCCESS:
      return {
        ...state,
        duplicateLoading: false,
      };
    case MB_DUPLICATE_ELEMENT_ERROR:
      return {
        ...state,
        duplicateLoading: true,
      };
    /** Message Builder Video Element */
    case MESSAGE_VIDEO_ELEMENT:
      return {
        ...state,
        loading: true,
      };
    case MESSAGE_VIDEO_ELEMENT_SUCCESS:
      return {
        ...state,
        loading: true,
      };
    case MESSAGE_VIDEO_ELEMENT_ERROR:
      return {
        ...state,
        loading: true,
      };

    case SET_VISIBLE_UPDATE:
      return {
        ...state,
        MBElements: {
          ...state.MBElements,
          ...state.MBElements?.sycu_build_elements_details?.filter(item =>
            item.build_elements_details_id === payload.id
              ? (item.is_visible = payload.is_visable)
              : item
          ),
        },
      };
    case BUILD_TITLE_CHANGE:
      return {
        ...state,
        MBElements: {
          ...state.MBElements,
          build_title: payload,
        },
      };
    case BUILD_SUB_TITLE_CHANGE:
      return {
        ...state,
        MBElements: {
          ...state.MBElements,
          build_sub_title: payload,
        },
      };
    case BUILDER_ACTION_UNDO:
      const newUndoState = state.undo;
      const addToRedo = newUndoState.pop();
      const newBuilds = newUndoState[newUndoState.length - 1];
      return {
        ...state,
        redo: [clonedeep(addToRedo), ...clonedeep(state.redo)],
        MBElements: newBuilds,
      };
    case STATE_ACTION:
      return {
        ...state,
        stateAction: payload,
      };
    case BUILDER_ACTION_REDO:
      const newRedoState = state.redo;
      const addToUndo = newRedoState.shift();
      return {
        ...state,
        undo: [...state.undo, addToUndo],
        redo: newRedoState,
        MBElements: clonedeep(addToUndo),
      };
    case BUILDER_BUILD_CHANGE:
      return {
        ...state,
        undo: state.undo.length === 0 ? [payload] : [...state.undo, payload],
        redo: state.redo.length ? [] : clonedeep(state.redo),
      };
    case CLEAR_UNDO_REDO_STATE:
      return {
        ...state,
        undo: [],
        redo: [],
      };
    case CHANGE_MESSAGE_ELEMENT:
      return {
        ...state,
        MBElements: {
          ...state.MBElements,
          ...state.MBElements?.sycu_build_elements_details?.map(item =>
            item.build_elements_details_id === payload.id
              ? ((item.title = payload.title),
                (item.content = payload.content),
                (item.extra_title = payload.extra_title),
                (item.extra_content = payload.extra_content),
                (item.extra_title_1 = payload.extra_title_1),
                (item.extra_content_1 = payload.extra_content_1),
                (item.is_visible = payload.is_visible))
              : item
          ),
        },
      };
    case RIGHT_BAR_SCROLL:
      return {
        ...state,
        right_bar_scroll: payload,
      };
    default:
      return state;
  }
};

export default MBReducer;
