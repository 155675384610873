/** Message Builder list */
export const FETCH_MB_CUSTOM_BUILD_LIST = "FETCH_MB_CUSTOM_BUILD_LIST";
export const FETCH_MB_CUSTOM_BUILD_LIST_SUCCESS =
  "FETCH_MB_CUSTOM_BUILD_LIST_SUCCESS";
export const FETCH_MB_CUSTOM_BUILD_LIST_ERROR =
  "FETCH_MB_CUSTOM_BUILD_LIST_ERROR";

//Set MbCustom List PageNo
export const SET_PAGE_MB_CUSTOM_BUILD_LIST = "SET_PAGE_MB_CUSTOM_BUILD_LIST";

/** Message Builder DUPLICATE */
export const MB_DUPLICATE = "MB_DUPLICATE";
export const MB_DUPLICATE_SUCCESS = "MB_DUPLICATE_SUCCESS";
export const MB_DUPLICATE_ERROR = "MB_DUPLICATE_ERROR";

/** Message Builder DELETE */
export const MB_DELETE = "MB_DELETE";
export const MB_DELETE_SUCCESS = "MB_DELETE_SUCCESS";
export const MB_DELETE_ERROR = "MB_DELETE_ERROR";

/** Message Builder SHARE LINK */
export const MB_SHARE_LINK = "MB_SHARE_LINK";
export const MB_SHARE_LINK_SUCCESS = "MB_SHARE_LINK_SUCCESS";
export const MB_SHARE_LINK_ERROR = "MB_SHARE_LINK_ERROR";

/** Message Builder Upload Build Image **/
export const MB_UPLOAD_BUILD_IMAGE = "MB_UPLOAD_BUILD_IMAGE";
export const MB_UPLOAD_BUILD_IMAGE_SUCCESS = "MB_UPLOAD_BUILD_IMAGE_SUCCESS";
export const MB_UPLOAD_BUILD_IMAGE_ERROR = "MB_UPLOAD_BUILD_IMAGE_ERROR";

/** To update redux when Message is deleted */
export const SET_MB_LISTING = "SET_MB_LISTING";

/***********Library Listing */
export const SET_MB_LIBRARY = "SET_MB_LIBRARY";

export const RESET_MB_CUSTOM_BUILD_LIST = "RESET_MB_CUSTOM_BUILD_LIST";

export const SET_PAGE_MB_SORT = "SET_PAGE_MB_SORT";

export const SAVE_ON_ELEMENT_CHANGE = "SAVE_ON_ELEMENT_CHANGE";
export const SAVE_ON_ELEMENT_CHANGE_SUCCESS = "SAVE_ON_ELEMENT_CHANGE_SUCCESS";
export const SAVE_ON_ELEMENT_CHANGE_ERROR = "SAVE_ON_ELEMENT_CHANGE_ERROR";

export const MB_DELETE_CUSTOM_RESTORE = "MB_DELETE_CUSTOM_RESTORE";
export const MB_DELETE_CUSTOM_RESTORE_SUCCESS =
  "MB_DELETE_CUSTOM_RESTORE_SUCCESS";
export const MB_DELETE_CUSTOM_RESTORE_ERROR = "MB_DELETE_CUSTOM_RESTORE_ERROR";

export const FETCH_MB_RESTORE_LIST = "FETCH_MB_RESTORE_LIST";
export const FETCH_MB_RESTORE_LIST_SUCCESS = "FETCH_MB_RESTORE_LIST_SUCCESS";
export const FETCH_MB_RESTORE_LIST_ERROR = "FETCH_MB_RESTORE_LIST_ERROR";

export const SAVE_ON_CHANGE = "SAVE_ON_CHANGE";
export const SAVE_ON_CHANGE_SUCCESS = "SAVE_ON_CHANGE_SUCCESS";
export const SAVE_ON_CHANGE_ERROR = "SAVE_ON_CHANGE_ERROR";

export const FETCH_MB_ELEMENTS = "FETCH_MB_ELEMENTS";
export const FETCH_MB_ELEMENTS_SUCCESS = "FETCH_MB_ELEMENTS_SUCCESS";
export const FETCH_MB_ELEMENTS_ERROR = "FETCH_MB_ELEMENTS_ERROR";
export const SET_MB_ELEMENTS = "SET_MB_ELEMENTS";

/** Message Builder save Custom Restore on change */
export const MB_SAVE_CUSTOM_RESTORE = "MB_SAVE_CUSTOM_RESTORE";
export const MB_SAVE_CUSTOM_RESTORE_SUCCESS = "MB_SAVE_CUSTOM_RESTORE_SUCCESS";
export const MB_SAVE_CUSTOM_RESTORE_ERROR = "MB_SAVE_CUSTOM_RESTORE_ERROR";

/** Message Builder Export */
export const FETCH_MB_EXPORT_ELEMENTS = "FETCH_MB_EXPORT_ELEMENTS";
export const FETCH_MB_EXPORT_ELEMENTS_SUCCESS =
  "FETCH_MB_EXPORT_ELEMENTS_SUCCESS";
export const FETCH_MB_EXPORT_ELEMENTS_ERROR = "FETCH_MB_EXPORT_ELEMENTS_ERROR";

/** On Message element Drag */
export const MESSAGE_ELEMENT_DRAG_DROP = "MESSAGE_ELEMENT_DRAG_DROP";
export const MESSAGE_ELEMENT_DRAG_DROP_SUCCESS =
  "MESSAGE_ELEMENT_DRAG_DROP_SUCCESS";
export const MESSAGE_ELEMENT_DRAG_DROP_ERROR =
  "MESSAGE_ELEMENT_DRAG_DROP_ERROR";

/** On Building Block Drag */
export const DRAG_BUILDING_BLOCK = "DRAG_BUILDING_BLOCK";
export const DRAG_BUILDING_BLOCK_SUCCESS = "DRAG_BUILDING_BLOCK_SUCCESS";
export const DRAG_BUILDING_BLOCK_ERROR = "DRAG_BUILDING_BLOCK_ERROR";

// MBElements Sorting By Index
export const HANDLE_MB_INDEX_SUCCESS = "HANDLE_MB_INDEX_SUCCESS";
export const HANDLE_ELEMENT_INDEX_SUCCESS = "HANDLE_ELEMENT_INDEX_SUCCESS";
export const SET_VISIBLE_UPDATE = "SET_VISIBLE_UPDATE";

export const GET_ACTIVE_SUBSCRIPTION_LIST = "GET_ACTIVE_SUBSCRIPTION_LIST";

/** Message Builder building blocks */
export const FETCH_BUILDING_BLOCKS_LISTING = "FETCH_BUILDING_BLOCKS_LISTING";
export const FETCH_BUILDING_BLOCKS_LISTING_SUCCESS =
  "FETCH_BUILDING_BLOCKS_LISTING_SUCCESS";
export const FETCH_BUILDING_BLOCKS_LISTING_ERROR =
  "FETCH_BUILDING_BLOCKS_LISTING_ERROR";

/** Message Builder DELETE */
export const MB_DELETE_ELEMENT = "MB_DELETE_ELEMENT";
export const MB_DELETE_ELEMENT_SUCCESS = "MB_DELETE_ELEMENT_SUCCESS";
export const MB_DELETE_ELEMENT_ERROR = "MB_DELETE_ELEMENT_ERROR";

/** Message Builder DUPLICATE */
export const MB_DUPLICATE_ELEMENT = "MB_DUPLICATE_ELEMENT";
export const MB_DUPLICATE_ELEMENT_SUCCESS = "MB_DUPLICATE_ELEMENT_SUCCESS";
export const MB_DUPLICATE_ELEMENT_ERROR = "MB_DUPLICATE_ELEMENT_ERROR";

/** Message Builder Video Element */
export const MESSAGE_VIDEO_ELEMENT = "MESSAGE_VIDEO_ELEMENT";
export const MESSAGE_VIDEO_ELEMENT_SUCCESS = "MESSAGE_VIDEO_ELEMENT_SUCCESS";
export const MESSAGE_VIDEO_ELEMENT_ERROR = "MESSAGE_VIDEO_ELEMENT_ERROR";

/***Building Block Search */
export const BB_SEARCH = "BB_SEARCH";
export const BB_SEARCH_SUCCESS = "BB_SEARCH_SUCCESS";
export const BB_SEARCH_ERROR = "BB_SEARCH_ERROR";

/****Teaching Elements */
export const SET_MB_TEACHING_ELEMENTS = "SET_MB_TEACHING_ELEMENTS";
export const FETCH_MB_TEACHING_ELEMENTS_SUCCESS =
  "FETCH_MB_TEACHING_ELEMENTS_SUCCESS";
export const FETCH_MB_TEACHING_ELEMENTS_ERROR =
  "FETCH_MB_TEACHING_ELEMENTS_ERROR";

/** Message Builder Teaching By Code*/
export const FETCH_MB_TEACHING_ELEMENTS_BY_CODE =
  "FETCH_MB_TEACHING_ELEMENTS_BY_CODE";
export const FETCH_MB_TEACHING_ELEMENTS_BY_CODE_SUCCESS =
  "FETCH_MB_TEACHING_ELEMENTS_BY_CODE_SUCCESS";
export const FETCH_MB_TEACHING_ELEMENTS_BY_CODE_ERROR =
  "FETCH_MB_TEACHING_ELEMENTS_BY_CODE_ERROR";

/** APP BUILDER ADD SECTION */
export const AB_DETAIL_ADD_SECTION = "AB_DETAIL_ADD_SECTION";
export const AB_DETAIL_ADD_SECTION_SUCCESS = "AAB_DETAIL_ADD_SUCCESS";
export const AB_DETAIL_ADD_SECTION_ERROR = "AB_DETAIL_ADD_SECTION_ERROR";

export const BUILDER_ACTION_UNDO = "BUILDER_ACTION_UNDO";
export const BUILDER_ACTION_REDO = "BUILDER_ACTION_REDO";
export const BUILDER_BUILD_CHANGE = "BUILDER_BUILD_CHANGE";
export const BUILD_TITLE_CHANGE = "BUILD_TITLE_CHANGE";
export const BUILD_SUB_TITLE_CHANGE = "BUILD_SUB_TITLE_CHANGE";
export const STATE_ACTION = "STATE_ACTION";
export const CLEAR_UNDO_REDO_STATE = "CLEAR_UNDO_REDO_STATE";
export const CHANGE_MESSAGE_ELEMENT = "CHANGE_MESSAGE_ELEMENT";
export const RIGHT_BAR_SCROLL = "RIGHT_BAR_SCROLL";

export const SET_INVITED_USERS = "SET_INVITED_USERS";

// element Loader

export const FETCH_MB_ELEMENTS_LOADER = "FETCH_MB_ELEMENTS_LOADER";
