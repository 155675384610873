import {
  FETCH_USER_FOLDER,
  FETCH_USER_FOLDER_SUCCESS,
  FETCH_USER_FOLDER_ERROR,
  // FETCH_USER_FOLDER_LOADING,
  FOLDER_ADD_EDIT,
  FOLDER_ADD_EDIT_SUCCESS,
  FOLDER_ADD_EDIT_ERROR,
  FOLDER_SEARCH,
  FOLDER_SEARCH_SUCCESS,
  FOLDER_SEARCH_ERROR,
  SET_INVITE_LB_DATA,
  SET_SIDEBAR_KEY,
} from "./actionTypes";

import produce from "immer";

const initialState = {
  FolderListingData: [],
  folder_count: null,
  fetchFolderLoading: false,
  FolderSave: false,
  user: null,
  //Share functionality
  inviteBuildData: {
    fromUser: "",
    buildTitle: "",
    inviteBuildId: 0,
  },
  sideBarKey: {},
};

const folderListingReducer = produce((state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    /**Get User folder list */
    case SET_SIDEBAR_KEY:
      return {
        ...state,
        sideBarKey: payload,
      };
    case FETCH_USER_FOLDER:
      return {
        ...state,
        fetchFolderLoading: true,
      };
    case FETCH_USER_FOLDER_SUCCESS:
      return {
        ...state,
        FolderListingData: action.payload,
        fetchFolderLoading: false,
        folder_count: payload.length,
      };
    case FETCH_USER_FOLDER_ERROR:
      return {
        ...state,
        fetchFolderLoading: true,
      };
    /** MB folder ADD/EDIT */
    case FOLDER_ADD_EDIT:
      return {
        ...state,
        loading: true,
        FolderSave: true,
      };
    case FOLDER_ADD_EDIT_SUCCESS:
      return {
        ...state,
        loading: false,
        FolderSave: false,
      };
    case FOLDER_ADD_EDIT_ERROR:
      return {
        ...state,
        loading: true,
        FolderSave: false,
      };

    /** MB folder Searching */
    case FOLDER_SEARCH:
      return {
        ...state,
        loading: true,
        fetchFolderLoading: true,
      };
    case FOLDER_SEARCH_SUCCESS:
      return {
        ...state,
        loading: false,
        FolderListingData: [
          ...state.FolderListingData.filter(
            item =>
              item?.build_folder_title
                .slice(0, action.payload.length)
                .toLowerCase() === action.payload.toLowerCase()
          ),
        ],
        fetchFolderLoading: false,
      };
    case FOLDER_SEARCH_ERROR:
      return {
        ...state,
        loading: true,
        fetchFolderLoading: false,
      };

    case SET_INVITE_LB_DATA:
      return {
        ...state,
        inviteBuildData: {
          ...state.inviteBuildData,
          ...payload,
        },
      };
    default:
      return state;
  }
});

export default folderListingReducer;
