import produce from "immer";
import { GET_TUTORIAL_DATA_BY_ID_SUCCESS, GET_TUTORIAL_DATA_SUCCESS } from "./actionType";

const initialState = {
    tutorialData: [],
    tutorialDetails: [],
};

const tutorialReducer = produce((state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case GET_TUTORIAL_DATA_SUCCESS:
            state.tutorialData = payload;
            break;
        case GET_TUTORIAL_DATA_BY_ID_SUCCESS:
            state.tutorialDetails = payload;
            break;
        default:
            return state;
    }
});

export default tutorialReducer;
