/** App Builder list */
export const FETCH_AB_CUSTOM_BUILD_LIST = "FETCH_AB_CUSTOM_BUILD_LIST";
export const FETCH_AB_CUSTOM_BUILD_LIST_SUCCESS =
  "FETCH_AB_CUSTOM_BUILD_LIST_SUCCESS";
export const FETCH_AB_CUSTOM_BUILD_LIST_ERROR =
  "FETCH_AB_CUSTOM_BUILD_LIST_ERROR";

/*Reset ABCustom Build List */
export const RESET_AB_CUSTOM_BUILD_LIST = "RESET_AB_CUSTOM_BUILD_LIST";

/*Reset AB FOLDER Build List */
export const RESET_AB_FOLDER_BUILD_LIST = "RESET_AB_FOLDER_BUILD_LIST";

/*Reset  FOLDER data */
export const RESET_FOLDER_DATA = "RESET_FOLDER_DATA";

/** Get Library Build */
export const GET_LIBRARY_BUILD = "GET_LIBRARY_BUILD";
export const GET_LIBRARY_BUILD_SUCCESS = "GET_LIBRARY_BUILD_SUCCESS";
export const GET_LIBRARY_BUILD_ERROR = "GET_LIBRARY_BUILD_ERROR";

/** Get Library Folder using type*/
export const GET_LIBRARY_FOLDER_BY_TYPE = "GET_LIBRARY_FOLDER_BY_TYPE";
export const GET_LIBRARY_FOLDER_BY_TYPE_SUCCESS =
  "GET_LIBRARY_FOLDER_BY_TYPE_SUCCESS";
export const GET_LIBRARY_FOLDER_BY_TYPE_ERROR =
  "GET_LIBRARY_FOLDER_BY_TYPE_ERROR";

/** Message Builder list */
export const FETCH_MB_CUSTOM_BUILD_LIST = "FETCH_MB_CUSTOM_BUILD_LIST";
export const FETCH_MB_CUSTOM_BUILD_LIST_SUCCESS =
  "FETCH_MB_CUSTOM_BUILD_LIST_SUCCESS";
export const FETCH_MB_CUSTOM_BUILD_LIST_ERROR =
  "FETCH_MB_CUSTOM_BUILD_LIST_ERROR";

/****Get Folder Details Data ****/
export const FETCH_FOLDER_DATA = "FETCH_FOLDER_DATA";
export const FETCH_FOLDER_DATA_SUCCESS = "FETCH_FOLDER_DATA_SUCCESS";
export const FETCH_FOLDER_DATA_ERROR = "FETCH_FOLDER_DATA_ERROR";

/*** Get Build Listing */
export const FETCH_BUILD_DATA = "FETCH_BUILD_DATA";
export const FETCH_BUILD_DATA_SUCCESS = "FETCH_BUILD_DATA_SUCCESS";
export const FETCH_FOLDER_LESSON_DATA_SUCCESS =
  "FETCH_FOLDER_LESSON_DATA_SUCCESS";
export const FETCH_BUILD_DATA_ERROR = "FETCH_BUILD_DATA_ERROR";
export const RESET_BUILD_LIST = "RESET_BUILD_LIST";

/** Message Builder DELETE */
export const MB_DELETE = "MB_DELETE";

/** To update redux when Message is deleted */
export const SET_MB_LISTING = "SET_MB_LISTING";

/** Get Series data */
export const FETCH_SERIES_DATA = "FETCH_SERIES_DATA";
export const FETCH_SERIES_DATA_SUCCESS = "FETCH_SERIES_DATA_SUCCESS";
export const FETCH_SERIES_DATA_ERROR = "FETCH_SERIES_DATA_ERROR";

/** Lesson Builder Upload Build Image **/
export const LB_UPLOAD_BUILD_IMAGE = "LB_UPLOAD_BUILD_IMAGE";
export const LB_UPLOAD_BUILD_IMAGE_SUCCESS = "LB_UPLOAD_BUILD_IMAGE_SUCCESS";
export const LB_UPLOAD_BUILD_IMAGE_ERROR = "LB_UPLOAD_BUILD_IMAGE_ERROR";

/****Get AB Folder Details Data ****/
export const FETCH_AB_FOLDER_DATA = "FETCH_AB_FOLDER_DATA";
export const FETCH_AB_FOLDER_DATA_SUCCESS = "FETCH_AB_FOLDER_DATA_SUCCESS";
export const FETCH_AB_FOLDER_DATA_ERROR = "FETCH_AB_FOLDER_DATA_ERROR";

/** Get AB USING FOLDER ID*/
export const FETCH_AB_FOLDER_BUILD_LIST = "FETCH_AB_FOLDER_BUILD_LIST";
export const FETCH_AB_FOLDER_BUILD_LIST_SUCCESS =
  "FETCH_AB_FOLDER_BUILD_LIST_SUCCESS";
export const FETCH_AB_FOLDER_BUILD_LIST_ERROR =
  "FETCH_AB_FOLDER_BUILD_LIST_ERROR";

/** App Builder DUPLICATE */
export const AB_DUPLICATE = "AB_DUPLICATE";
export const AB_DUPLICATE_SUCCESS = "AB_DUPLICATE_SUCCESS";
export const AB_DUPLICATE_ERROR = "AB_DUPLICATE_ERROR";

export const SET_FOLDER_ID = "SET_FOLDER_ID";
export const SET_MINISTRY_TYPE = "SET_MINISTRY_TYPE";

//update redux after delete
export const SET_AB_LISTING_BY_FOLDER_ID = "SET_AB_LISTING_BY_FOLDER_ID";

// SET_TAB_VIEW

export const SET_TAB_VIEW = "SET_TAB_VIEW";

// Store Id
export const STORE_ACTIVE_TAB_ID = "STORE_ACTIVE_TAB_ID";

export const STORE_ACTIVE_BREADCRUMB = "STORE_ACTIVE_BREADCRUMB";

export const BUILDER_COMPONENT_CHANGE = "BUILDER_COMPONENT_CHANGE";
